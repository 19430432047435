import {Component, OnDestroy, OnInit} from '@angular/core';
import {interval, Subject, Subscription} from 'rxjs';
import {StorageService} from './shared/services/storage.service';
import {GlobalService} from './shared/services/global.service';
import {Router} from '@angular/router';
import {AuthService} from './shared/services/auth.service';
import {UniversityService} from "./shared/services/university.service";
import {ProgressBarService} from "./shared/services/progress-bar.service";
import {switchMap, takeUntil} from "rxjs/operators";
import {StudentService} from "./shared/services/student.service";

@Component({
  selector: 'ps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ums';
  private _unsubscribeAll: Subject<any>;
  userData: any;
  user: any;
  progressBarValue: number = 0;
  progressbarStatus: any = false;
  batchId: any = [];
  private progressBarSubscription: Subscription;

  constructor(private globalService: GlobalService,
              private universityService: UniversityService,
              private studentService: StudentService,
              private progressBarService: ProgressBarService,
              private router: Router,
              private authService: AuthService) {
    this.user = StorageService.getItem('self');
    this._unsubscribeAll = new Subject();
    this.checkLogin();

    this.globalService.reloadSelf.subscribe((d) => {
      if (d) {
        this.reloadSelf();
        this.globalService.reloadSelf.next(false);
      }
    });
  }

  ngOnInit(): void {
    this.getUserDetail();
    // this.initProgressBarSubscription();
    /*this.initProgressBarSubscription();
    this.getRefreshBatches();*/
  }

  getUserDetail() {
    if (this.user && this.user.user_id) {
      this.universityService.detail(this.user.user_id).subscribe(data => {
        if (data) {
          this.userData = data;
          if (this.userData && this.userData['user_type'] && this.userData['user_type'] === 'ACADEMIC_STAFF') {
            this.router.navigateByUrl('students/final-verification')
          }
        }
      });
    }
  }

  initProgressBarSubscription() {
    this.progressBarSubscription = this.progressBarService.progressBar$.subscribe(
      (value) => {
        this.progressbarStatus = true;
        this.progressBarValue = value;
        if (this.progressBarValue === 100) {
          setTimeout(() => {
            this.progressbarStatus = false;
          }, 5000);
        }
      }
    );
  }

  // Code left un-commented for further modifications
  getRefreshBatches() {
    this.studentService.getRefreshBatches().subscribe(dt => {
      if (dt) {
        dt.forEach(batches => {
          this.batchId.push(batches.id);
        });
        if (this.batchId.length > 0) {
          for (let i = 0; i < this.batchId.length; i++) {
            const pollingInterval = 2000;
            const polling$ = interval(pollingInterval);
            const stopPolling$ = new Subject<void>();
            polling$
              .pipe(
                switchMap(() => this.studentService.jobBatch(this.batchId[i])),
                takeUntil(stopPolling$)
              )
              .subscribe((data) => {
                if (data && data.id) {
                  this.progressBarValue = data.progress;
                  // Update the progress bar value in the service
                  this.progressBarService.updateProgressBarValue(this.progressBarValue);
                  if (this.progressBarValue === 100) {
                    stopPolling$.next();
                  }
                }
              });
          }
        }
      }
    }, error => {

    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.progressBarSubscription.unsubscribe();
  }

  checkLogin() {
    if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
    } else {
      this.router.navigateByUrl('auth/login');
    }
  }

  reloadSelf() {
    this.authService.self({all: true}).subscribe((data) => {
      this.globalService.setSelf({
        'name': data.name,
        'user_id': data.id,
        'username': data.username,
        'email': data.email,
        'contact': data.contact,
        'belongs_to': data.belongs_to
      });
      return;
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('auth/login');
    });
  }
}
