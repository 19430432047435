<div class="authentication-main auth-v2"  style="background-image: url('assets/images/other-images/final-background.jpg');">
  <ng-template #content let-modal>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title font-weight-bold">User Registration OTP</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalService.dismissAll('')"
        >
          ×
        </button>
      </div>
      <div class="modal-body" ngbAutoFocus>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form class="form" novalidate [formGroup]="registrationOtpForm">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <fieldset class="form-group">
                    <input class="form-control" type="number" formControlName="otp"
                           placeholder="Enter OTP">
                    <small *ngIf="submittedOtp && registrationOtpForm.get('otp').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
            <small>Resend OTP in - </small>
            <small class="text-danger mr-1"
                   *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
            <small class="text-primary cursor-pointer"
                   *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP ?
            </small>
          </div>
        </div>
      </div>
      <!--<div class="modal-footer">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="submitOtp()"
                    [disabled]="submittingOtp">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Submit
            </button>
          </div>
        </div>
      </div>-->
    </div>
  </ng-template>
  <div class="row m-0">
    <a class="brand-logo">
      <img src="assets/images/logo/new-logo-png.png" alt="brand-logo" height="100"/>
    </a>
    <div class=" d-lg-flex col-lg-7 align-items-center p-5">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img class="img-fluid" width="800px" height="650px"
             src="assets/images/other-images/5.png"
             alt="Login V2"/>
      </div>
    </div>
    <div class="d-flex col-lg-5 align-items-center  px-2 p-lg-5 ">
      <div class="col-8 col-sm-8 col-md-6 col-lg-10 px-xl-4 mx-auto login-card ">
        <h1 class=" text-center py-2" style="color: #2A413B">
          <img src="assets/images/logo/new-logo-png.png" alt="VGU" height="100px"/>
        </h1>
          <h5 class=" text-center py-2" style="color: #2A413B">Log In to <strong>University Panel</strong> and Start Managing !</h5>
          <form class="auth-login-form mt-2" [formGroup]="loginForm">
                    <div class="form-group text-left ">
                      <label style="font-size: 15px;"><strong>Username</strong></label>
                      <input class="form-control border-radius-0" type="text" placeholder="volmint"
                             formControlName="username"/>
                    </div>
            <div class="form-group text-left">
              <label class="col-form-label" style="font-size: 15px;"><strong>Password</strong></label>
              <input class="form-control border-radius-0" type="password" placeholder="*******"
                     formControlName="password"/>
            </div>
            <div class="form-group text-left text-info mt-55px">
              <div class="row">
                <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <small class="col-form-label f_12 cursor-pointer">Forgot Password ?</small>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                  <small class="col-form-label f_12 cursor-pointer">Forgot Username ?</small>
                </div>-->
              </div>
            </div>
            <div class="form-group form-row mt-4 mb-0">
              <button class="btn btn-primary btn-block" type="submit" (click)="signIn()">Log In</button>
            </div>
          </form>

      </div>
    </div>
  </div>
</div>
