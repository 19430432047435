import { Component, OnInit } from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../shared/services/storage.service';
import {CenterService} from '../../shared/services/center.service';
import {FileService} from '../../shared/services/file.service';
import {AlertService} from '../../shared/services/alert.service';
import {NgbModal, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {ProfileService} from '../../shared/services/profile.service';
import {DataService} from '../../shared/services/data.service';
import {UniversityService} from "../../shared/services/university.service";
import {SubPermissionService} from "../../shared/services/sub-permission.service";

@Component({
  selector: 'ps-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  password_icon: boolean = false;
  addProfileForm: FormGroup;
  updatePasswordForm: FormGroup;
  imageChangedEvent: any;
  croppedImage: any;
  croppedImageEvent: any;
  profileImageUploadArea: any = 'assets/images/image-backgrounds/200-200.png';
  profileImage: any
  userData: any;
  subPermissionArray: any = [];
  modalType: any;
  user: any;
  submitted: any = false;
  inputTypePasswordOld: any = 'password';
  inputTypePasswordNew: any = 'password';
  inputTypePasswordConfirm: any = 'password';
  constructor(config: NgbPaginationConfig,
              private modalService: NgbModal,
              private alertService: AlertService,
              private fileService: FileService,
              private profileService: ProfileService,
              private centerService: CenterService,
              private universityService: UniversityService,
              private subPermissionService: SubPermissionService,
              private dataService: DataService,
              private fb: FormBuilder) {
    this.user = StorageService.getItem('self');
    config.size = 'sm';
    config.boundaryLinks = true;
  }


  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.addProfileForm = this.fb.group({
      name: [this.user.name, Validators.required],
      code: [this.user.username, Validators.required],
      username: [this.user.username, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      contact: [this.user.contact, Validators.required],
    });
    this.updatePasswordForm =  this.fb.group( {
      'current_password': ['', Validators.required],
      'new_password': ['', Validators.required],
      'confirm_password': ['', Validators.required],
    });
    this.getDetails();
  }

  update() {
    this.alertService.showSuccess({title: 'Success', message: 'Profile updated successfully'});
  }

  openLg(content) {
    this.modalType = 'PASSWORD';
    this.modalService.open(content, {size: 'lg'});
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImageEvent = event;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onImageChange(e) {
    this.imageChangedEvent = e;
  }

  openProfileModel(content) {
    this.modalType = 'PROFILE_IMAGE';
    this.modalService.open(content);
  }

  saveProfileImage() {
    const params = {
      'type': 'PROFILE_IMAGE',
      'user_id': this.user.user_id,
      'fileType': 'BASE64'
    };
    this.fileService.fileUpload(params, this.croppedImage).subscribe(data => {
      if (data) {
        this.modalService.dismissAll('');
        this.imageChangedEvent = undefined;
        this.updateProfileImage({'user_id': this.user.user_id, 'profile_image': data['data'].id});
      }
    });
  }

  updateProfileImage(data) {
    this.profileService.updateProfile(this.user.user_id, data).subscribe(data => {
      this.getDetails();
    });
  }

  getDetails() {
    if (this.user && this.user.user_id) {
      this.universityService.detail(this.user.user_id).subscribe(data => {
        if (data) {
          this.userData = data;
          let subPermissionsArr = [];
          if (this.userData['user_id'] && this.userData['user_id'] === 1) {
            this.assignAllSubPermissions();
          } else {
            if (this.userData && this.userData['role'] && this.userData['role']['role_permissions'] && this.userData['role']['role_permissions'].length > 0) {
              this.userData['role']['role_permissions'].forEach(rolePermission => {
                subPermissionsArr.push(rolePermission['sub_permission']['meta']);
              });
              this.subPermissionArray = subPermissionsArr;
            }
          }
        }
      });
    }
  }
  assignAllSubPermissions() {
    this.subPermissionService.get({'page': -1}).subscribe(data => {
      if (data && data['data'].length > 0) {
        if (data && data['data'].length > 0) {
          let subPermissionArray = [];
          data['data'].forEach(dt => {
            if (dt && dt.meta) {
              subPermissionArray.push(dt.meta);
            }
          });
          this.subPermissionArray = subPermissionArray;
        }
      }
    });
  }
  updatePassword() {
    this.submitted = true;
    if (!this.updatePasswordForm.valid) {
      return;
    }
    if(this.updatePasswordForm.value.new_password === this.updatePasswordForm.value.confirm_password) {
      if(this.submitted) {
        const params = {
          'new_password': (this.updatePasswordForm && this.updatePasswordForm.getRawValue() && this.updatePasswordForm.getRawValue().new_password) ? this.updatePasswordForm.getRawValue().new_password : '',
          'current_password': (this.updatePasswordForm && this.updatePasswordForm.getRawValue() && this.updatePasswordForm.getRawValue().current_password) ? this.updatePasswordForm.getRawValue().current_password : '',
        };
        this.profileService.updatePassword(params).subscribe(data => {
          this.submitted = false;
          this.updatePasswordForm.patchValue({
            'new_password': '',
            'confirm_password': '',
            'current_password': ''
          });
          this.modalService.dismissAll('');
        }, error => {
          if (error && error['error'] && error['error']['errorDetails']) {
            this.alertService.showErrors(error['error']['errorDetails']);
          } else {
            this.alertService.showErrors('Something went wrong, please try again');
          }
          this.submitted = false;
        });
      }
    } else {
      this.submitted = false;
      this.alertService.showErrors('Password and Confirm Password didn\'t match');
    }
  }
}
