import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class StudentService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.STUDENT(), data);
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.STUDENT(), data);
  }

  getCenterStudentsAmount(session_id): Observable<any> {
    return this.getRequest(AppUrl.CENTER_STUDENT_AMOUNT(session_id));
  }

  getCenterStudents(data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_STUDENT(), data);
  }

  jobBatch(id): Observable<any> {
    return this.getRequest(AppUrl.JOB_BATCH(id));
  }

  getRefreshBatches(): Observable<any> {
    return this.getRequest(AppUrl.REFRESH_BATCH());
  }

  downloadCenterStudents(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_CENTER_STUDENT(), data);
  }


  downloadVerifyStudentReport(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_VERIFY_STUDENT(), data);
  }

  downloadFinalStudentList(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_FINAL_STUDENT_LIST(), data);
  }

  downloadDirectStudents(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_DIRECT_STUDENT(), data);
  }

  downloadMarkedBackExamsReport(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_MARKED_BACK_EXAM(), data);
  }

  getDirectStudents(data): Observable<any> {
    return this.getRequest(AppUrl.DIRECT_STUDENT(), data);
  }

  getCenterBranchStudents(data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_BRANCH_STUDENT(), data);
  }

  getBranchStudents(data): Observable<any> {
    return this.getRequest(AppUrl.BRANCH_STUDENT(), data);
  }

  getTotalStudentCount(id): Observable<any> {
    return this.getRequest(AppUrl.TOTAL_STUDENT_COUNT(id));
  }

  getTotalVerifiedStudentsCount(id): Observable<any> {
    return this.getRequest(AppUrl.TOTAL_VERIFIED_STUDENT_COUNT(id));
  }

  getTotalEnrolledStudentsCount(id): Observable<any> {
    return this.getRequest(AppUrl.TOTAL_ENROLLED_STUDENT_COUNT(id));
  }

  getFreshStudent(id): Observable<any> {
    return this.getRequest(AppUrl.FRESH_STUDENT_COUNT(id));
  }

  getLateralEntryStudent(id): Observable<any> {
    return this.getRequest(AppUrl.LATERAL_ENTRY_STUDENT_COUNT(id));
  }

  getReRegisterStudent(id): Observable<any> {
    return this.getRequest(AppUrl.RE_REGISTER_STUDENT_COUNT(id));
  }

  getProcessStudents(data): Observable<any> {
    return this.getRequest(AppUrl.PROCESS_STUDENT(), data);
  }

  getProcessVerifiedStudents(data): Observable<any> {
    return this.getRequest(AppUrl.PROCESS_VERIFIED_STUDENT(), data);
  }

  getEvaluationStudents(data): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_EVALUATION(), data);
  }

  addStudent(data): Observable<any> {
    return this.postRequest(AppUrl.ADD_STUDENT(), data);
  }

  detail(unique_id, data): Observable<any> {
    return this.getRequest(AppUrl.STUDENT(unique_id), data);
  }

  detailByUserId(user_id, data): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_BY_USER_ID(user_id), data);
  }

  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.STUDENT(id), data);
  }

  verifyStudent(id, data): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_VERIFY(id), data);
  }

  finalVerifyStudent(id, data): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_FINAL_VERIFY(id), data);
  }

  finalBulkVerifyStudent(id, data): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_BULK_FINAL_VERIFY(id), data);
  }


  resendOTP(data): Observable<any> {
    return this.postRequest(AppUrl.RESEND_OTP_STUDENT(), data);
  }

  downloadStudentDocument(id, data): Observable<any> {
    return this.postRequest(AppUrl.STUDENTS_DOCUMENT_DOWNLOAD(id), data);
  }

  assignEnrollment(id, data): Observable<any> {
    return this.getRequest(AppUrl.ASSIGN_ENROLLMENT(id), data);
  }

  assignRollNo(id): Observable<any> {
    return this.getRequest(AppUrl.ASSIGN_ROLL_NO(id));
  }

  getReRegisterStudents(data): Observable<any> {
    return this.getRequest(AppUrl.RE_REGISTER_STUDENT(), data);
  }

  assignProEnrollment(id, data): Observable<any> {
    return this.putRequest(AppUrl.ASSIGNED_PRO_ENROLLMENT(id), data);
  }

  assignSpecialEnrollment(id, data): Observable<any> {
    return this.putRequest(AppUrl.ASSIGNED_SPECIAL_ENROLLMENT(id), data);
  }
  downloadStudents(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_STUDENT(), data);
  }

  downloadReRegisteredStudents(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_RE_REGISTERED_STUDENT(), data);
  }

  delete(id): Observable<any> {
    return this.deleteRequest(AppUrl.STUDENT(id));
  }

  getBackExamStudents(data): Observable<any> {
    return this.getRequest(AppUrl.BACK_EXAM_STUDENTS(), data);
  }

  updateMarkedStudentBackExamStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_MARKED_STUDENT_BACK_EXAM_STATUS(id), data);
  }

  downloadVerifyStudent(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_PROCESS_STUDENT(), data);
  }
  downloadAssignEnrollmentStudentReport(data): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_ENROLLMENT_STUDENT(), data);
  }
  sendEnrollmentNo(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.SEND_ENROLLMENT_NO(unique_id), data);
  }

}
